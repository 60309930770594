import Vue from "vue";

import {
    resolve_domain_url
} from "@/utils/url_helpers";

// https://github.com/jojax/django-js-error-hook/blob/master/django_js_error_hook/templates/django_js_error_hook/utils.js

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

(function() {

    function logError(details) {
      var xhr = new XMLHttpRequest();
      // xhr.open("POST", "{% url 'js-error-handler' %}", true);
      xhr.open("POST", resolve_domain_url("js_error_hook/"), true);
      xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
      var cookie = getCookie("csrftoken");
      if (cookie) {
        xhr.setRequestHeader("X-CSRFToken", cookie);
      }
      var query = [],
        data = {
          context: navigator.userAgent,
          details: details
        };
      for (var key in data) {
        query.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        );
      }
      xhr.send(query.join("&"));
    }



    window.onerror = function(msg, url, line_number, column_number, error_obj) {
      var log_message = url + ": " + line_number + ": " + msg;
      if (column_number) {
        log_message += ", " + column_number;
      }
      if (error_obj && error_obj.stack) {
        log_message += ", " + error_obj.stack;
      }
      logError(log_message);
    };
    if(process.env.NODE_ENV === 'production'){
      Vue.config.errorHandler = (error, vm, info) => {
        let log_message = "";
        const url = window.location.href;
        try {
          const json_error = JSON.stringify(
            error,
            Object.getOwnPropertyNames(error)
          );
          log_message = `${url} : ${json_error}`;
        } catch (error) {
          log_message = `${url} : ${error.message} : ${error.stack}`;
        }
        logError(log_message);
      };
    }
    if (window.addEventListener) {
      window.addEventListener("unhandledrejection", function(rejection) {
        var log_message = rejection.type;
        if (rejection.reason) {
          if (rejection.reason.message) {
            log_message += ", " + rejection.reason.message;
          } else {
            log_message += ", " + JSON.stringify(rejection.reason);
          }
          if (rejection.reason.stack) {
            log_message += ", " + rejection.reason.stack;
          }
        }
        logError(log_message);
      });
    }
  }
)(resolve_domain_url, Vue, getCookie);