<template>
	<div>
		<div class="postlist-post" v-bind:class=[fadeinPostItem]>
			<div class="card mb-4 border-0">
				<div class="card-img-container" v-on:click="handlePostClick">
					<img class="card-img-top" v-bind:alt="post.interviewee.name" v-bind:src="postImagePath" v-on:load="cardImageLoaded()" v-bind:style="{visibility: isImageLoading ? 'hidden' : 'visible'}">
				</div>
				<div class="card-body">
					<p v-if="post.text" class="card-text post-text">{{partialText}}
						<router-link :to="getAbsoluteUrl">...</router-link>
						<i v-if="post.interviewee.name || post.institution">({{post.interviewee.name }}, {{post.institution }})</i>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import router from '@/router'
import {resolve_static_asset, resolve_domain_url} from '@/utils/url_helpers'

function timeConverter(timestamp) {
	let a = new Date(timestamp * 1000);
	let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	let year = a.getFullYear();
	let month = months[a.getMonth()];
	let date = a.getDate();
	// let hour = a.getHours();
	// let min = a.getMinutes();
	// let sec = a.getSeconds();
	let time = date + ' ' + month + ' ' + year;
	return time;
}
export default {
	name: 'post-list-view',
	props: ['post',],
	// components: {RouterLink},
	data: function(){
		return {
			fadeinPostItem: 'fade-in',
			isImageLoading: true,
		}
	},	
	computed: {
		partialText(){
			const total_length = 400
			const name_length = `( ${this.post.interviewee.name}, ${this.post.institution} )`.length;
			const last_space = this.post.text.lastIndexOf(" ", total_length - name_length)
			return this.post.text.substring(0, last_space)
		},
		formatedDate(){
			return timeConverter(this.post.timestamp)
		},
		getAbsoluteUrl(){
			return `/post/${this.post.interviewee.slug}/${this.post.timestamp}/`
		},
		postImagePath(){
			return this.getStaticAsset(`_forall/${this.post.image_name}`);
		}
	},
	methods: {
		handlePostClick(){					
			router.push(this.getAbsoluteUrl);
		},
		cardImageLoaded(){
			this.$data.isImageLoading = false;
		},
		getStaticAsset(path){
			return resolve_static_asset(path);
		}
	},
}

</script>
<style scoped>
	.card-img-container{
		background-color:#eaeaea;
	}
</style>
