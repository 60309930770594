<template>
  <div id="app">    
    <nav v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" class="navbar navbar-light bg-light">
      <router-link class="navbar-brand" :to="{name: 'home'}" title="∀">
        <img v-bind:src="getStaticAsset('assets/forall-symbol.svg')"  width="30" height="30" alt="∀"/>
      </router-link>
    </nav>
    <nav id="bottom-navbar" v-if="showBottomMenu" class="navbar fixed-bottom navbar-light bg-light slide-up ">
      <router-link class="navbar-brand" :to="{name: 'home'}" title="∀">
        <img v-bind:src="getStaticAsset('assets/forall-symbol.svg')"  width="30" height="30" alt="∀"/>
      </router-link> 
        <div class="nav navbar-nav">
          <a href="/contribution.pdf ">Contribute an interview</a>
          <!--
          <router-link :to="{name: 'home'}" class="nav-link navbar-text" title="Latest Posts">LATEST</router-link>
          <router-link :to="{name:'about'}" class="nav-link navbar-text" title="About">ABOUT</router-link>
          <router-link :to="{name:'contact'}" class="nav-link navbar-text" title="About">CONTACT</router-link>
          -->
      </div>
    </nav>     
    <div v-if="errorMsg" class="alert alert-warning" role="alert">
      {{errorMsg}}
    </div>
    <load-spinner/>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import store from "@/store";
import router from '@/router';

import {resolve_static_asset, resolve_domain_url} from '@/utils/url_helpers'

import LoadSpinner from '@/components/LoadSpinner.vue';

export default {
  name: 'App',
  components:{LoadSpinner},   
  data:() => {  
    return {
      errorMsg: '',
      // showBottomMenu: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
      } // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API        
    }
  },
  computed: {
      ...mapState({
          posts: state => state.posts.posts,
          showBottomMenu: state => {
            return state.posts.showBottomMenu
          },
      }),
      ...mapGetters(['posts/activePosts'])
  },
  metaInfo() {
    const absolute = true;
    return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: '',
        // all titles will be injected into this template
        titleTemplate: '%s | For All',
        links: [
          {rel: 'canonical', href: resolve_domain_url(this.$route.fullPath)}
        ],    
        meta:[
          { name: 'description', content: 'Math is for all. Computer science is for all. Let me show you---one mathematician or computer scientist every other week.', vmid: 'description',},
          //Facebook
          { property: 'og:site_name', content: 'For All', vmid: 'og:title',},
          { property: 'og:description', content: 'Math is for all. Computer science is for all. Let me show you---one mathematician or computer scientist every other week.', vmid: 'og:description',},
          { property: 'og:title',content: 'Math is For All',template: chunk => `${chunk} - For All`, vmid: 'og:title',},
          { property: 'og:image', content: resolve_static_asset('assets/forall-symbol.svg', {absolute}), vmid: 'og:image'},
          { property: 'og:type', content: 'website', vmid: 'og:type'},
          { property: 'og:url', content: window.location.href, vmid: 'og:url'},
          // Twitter
          { name: 'twitter:card', content: "summary", vmid: 'twitter:card'},
          { name: 'twitter:title',content: 'Math is For All', template: chunk => `${chunk} - For All`, vmid: 'twitter:title',},
          { name: 'twitter:description', content: 'Math is for all. Computer science is for all. Let me show you---one mathematician or computer scientist every other week.', vmid: 'twitter:description'},
          { name: 'twitter:image', content: resolve_static_asset('assets/forall-symbol.svg', {absolute}), vmid: 'twitter:image'},
          { name: 'twitter:url', content: window.location.href, vmid: 'twitter:url'},
          // Google / Schema.org markup:
          {itemprop: 'name', content: 'Math is For All', template: chunk => `${chunk} - For All`, vmid: 'itemprop:name',},
          {itemprop: 'description', content: 'Math is for all. Computer science is for all. Let me show you---one mathematician or computer scientist every other week.', vmid: 'itemprop:description',},
          {itemprop: 'image', content: resolve_static_asset('assets/forall-symbol.svg', {absolute}), vmid: 'itemprop:image',}
        ]      
      }
  },  
  methods:{
    getStaticAsset(path){
        return resolve_static_asset(path)
    },
    backClicked: ($route) => {
        if($route.path !== '/'){
          return router.back()
        }
        return false;
    },
    onWaypoint ({ going, direction }) {
      // going: in, out
      // direction: top, right, bottom, left
      if(this.$route.name !== 'home'){return;}
      if(going === 'out' && direction === 'top'){
          // this.showBottomMenu = true;
          store.dispatch('posts/toggleBottomMenu', true);
      }
      if(going === 'in' && direction === 'bottom'){
        // this.showBottomMenu = false;
        store.dispatch('posts/toggleBottomMenu', false);
      }
    }      
  },
  mounted: function() {
    const app_shell_items = document.getElementsByClassName('app-shell-item');
    while(app_shell_items.length > 0){
        window.debugger;
        var node = app_shell_items[0]
        if(node.parentNode != null){
          node.parentNode.removeChild(node);
        }
        
        // not supported in IE.
        // app_shell_items[0].remove();
    }      
    store.dispatch("posts/fetchPosts")
      .then( () => store.dispatch('posts/prefetchImgs') )
      .catch( error => {
      console.log(error)
      this.errorMsg = 'Error Fetching Posts';
    } )
  },   
}
</script>
