<template>
        <div v-if="post">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-6">						
                    <div class="postview-image">
                        <img class="postview-image" v-bind:src="postImagePath" v-bind:alt="post.interviewee.name">
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-sm-6">
                    <div class="postview-text">
                        <p class="card-text" v-for="(paragraph, index) in post.text.split('•')" v-bind:key="index">{{paragraph}}</p>
                        <p><i v-if="post.interviewee.name || post.institution">{{ post.interviewee.name }}, {{ post.institution }}</i></p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { mapState } from 'vuex'
import {resolve_static_asset, resolve_domain_url} from '@/utils/url_helpers'
import router from '@/router'

function timeConverter(timestamp) {
	let a = new Date(timestamp * 1000);
	let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	let year = a.getFullYear();
	let month = months[a.getMonth()];
	let date = a.getDate();
	// let hour = a.getHours();
	// let min = a.getMinutes();
	// let sec = a.getSeconds();
	let time = date + ' ' + month + ' ' + year;
	return time;
}

export default {
    name:"post-detail-view",
    // template: "#post-detail-view",    
    metaInfo() {   
        const post = this.post;
        const full_path = this.$route.fullPath;
        const  absolute  = true;
        if (typeof post === 'undefined'){ return {} }
        const postImagePath = `_forall/${this.post.image_name}`;
        
        return {
                title: post.interviewee.name,
                meta: [
                    { name: 'description', content: `Our conversation with ${post.interviewee.name} of ${post.interviewee.institution}`, vmid: 'description'},
                    { property: 'og:title', content: post.interviewee.name, vmid: 'og:title'},
                    { property: 'og:url', content: resolve_domain_url(full_path), vmid: 'og:url'},
                    { property: 'og:image', content: resolve_static_asset(postImagePath, { absolute }), vmid: 'og:image'},
                    { property: 'og:type', content: 'article', vmid: 'og:type'},
                    { property: 'og:description', content: `Our conversation with ${post.interviewee.name} of ${post.interviewee.institution}`, vmid: 'og:description'},
                    
                    { name: 'twitter:card', content: "summary", vmid: 'twitter:card'},
                    { name: 'twitter:title', content: post.interviewee.name, vmid: 'twitter:title'},
                    { name: 'twitter:url', content: resolve_domain_url(full_path), vmid: 'twitter:url'},
                    { name: 'twitter:image', content: resolve_static_asset(postImagePath,  { absolute }), vmid: 'twitter:image'},
                    { name: 'twitter:description', content: `Our conversation with ${post.interviewee.name} of ${post.interviewee.institution}`, vmid: 'twitter:description'},

                    {itemprop: 'name', content: post.interviewee.name, vmid: 'itemprop:name',},
                    {itemprop: 'description', content: `Our conversation with ${post.interviewee.name} of ${post.interviewee.institution}`, vmid: 'itemprop:description',},
                    {itemprop: 'image', content: resolve_static_asset(postImagePath, { absolute }), vmid: 'itemprop:image',}                    
                ]
            }
    },    
    computed: {
        ...mapState({
            // arrow functions can make the code very succinct!
            posts: state => state.posts,
            fetching: state => state.posts.fetching,
        }),				
        formatedDate(){
            return timeConverter(this.post.timestamp)
        },		
        post(){
            const post_id = this.$route.params.post_id;
            const post = this.posts.posts.find(obj => obj.timestamp == post_id);
            if(post){
                return post;
            }
            return post;
        },
		postImagePath(){
			return this.getStaticAsset(`_forall/${this.post.image_name}`);
		}        
    },
    methods: {
        gotoPost(url){
            router.push(url);
        },
        getStaticAsset(path){
            return resolve_static_asset(path)
        },        
    },
    mounted: function(){
        this.$store.dispatch('posts/toggleBottomMenu', true);
    }    
}


</script>
