<template>
    <div>
    <section class="jumbotron text-center">
    <div class="container">
        <h1 class="jumbotron-heading" style="font-weight:700;">FOR ALL</h1>
        <img v-bind:src="getStaticAsset('assets/forall-symbol.svg')" width="30" height="30" alt="∀" style="margin-bottom:10px;"/>
        <br>
        <p class="lead text-muted">Math is for all. Computer science is for all. Let me show you---one mathematician or computer scientist every other week.</p>
        <p>
        <!-- <a href="#" class="btn btn-default my-2">View Latest</a> -->
        </p>
    </div>
    </section>        
        <div class="row">
            <post-list-view v-for="post in activePosts" v-bind:key="post.post_id" v-bind:post="post" class="col-sm-6 col-md-4 col-lg-4 col-xl-4"></post-list-view>
            <!-- <div class="col-sm-4">
                <div class="postlist-post">
                    <div class="card border-0 empty-card">
                    <img class="card-img-top">
                        <div class="card-body">                        
                            <p class="card-text post-text">...
                            </p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import store from '@/store';
import {resolve_static_asset} from '@/utils/url_helpers'

import PostListView from '@/components/PostListView.vue'

window.onscroll = function(ev) {

};

function handle_infinite_scroll(){
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        store.dispatch('posts/postsNext', {prefetchNext: true})
    }
    // let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    // if (bottomOfWindow) {    
    //     // will to define store.
    //     store.dispatch('posts/postsNext', {prefetchNext: true})
    // }

}

export default {
    name: 'home-view',
    // template: "#home-view",
    metaInfo() {        
      return {
            title: 'Math is For All',
        }
    },
    components: {
        PostListView,
    },
    computed: {
        ...mapState({
            // arrow functions can make the code very succinct!
            posts: state => state.posts.posts,
        }),
        // activePosts: () => {
        //     debugger;
        // }    
        ...mapGetters({'activePosts': 'posts/activePosts'
        }),
    },
    methods:{
        ...mapActions(['posts/fetchPosts']),				
        scroll() {
            window.addEventListener('scroll', handle_infinite_scroll)
        },
        getStaticAsset(path){
            return resolve_static_asset(path)
        }
    },
    mounted: function () {
        this.scroll();
        store.dispatch('posts/toggleBottomMenu', false);
    },
    beforeDestroy: function(){
        window.removeEventListener('scroll', handle_infinite_scroll);
    }
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
