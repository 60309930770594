/* eslint-disable no-console */
/* eslint-disable no-debugger */
import Vue from 'vue'
import App from './App.vue'
import { mapState, mapActions, mapGetters } from "vuex";

import store from "@/store";
import router from "@/router";


Vue.config.productionTip = false


  const vue = new Vue({
    el: "#app",
    delimiters: ["${", "}"],
    store,
    router,
    render: h => h(App)
  }).$mount("#app");
// vue.$mount("#app");



// Vue.use(VueRouter)
// Vue.config.productionTip = false;
// const vue = new Vue({
//   router,
//   store,
//   render: h => h(App)
// });

// vue.$mount("#app");