function _clean_uri(uri){
    if(uri.startsWith("/")){
        uri = uri.slice(1, uri.length); 
    }
    return uri    
}

const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};


export function resolve_static_asset(asset, absolute=false) {
    // const publicPath = process.env.BASE_URL;
    const staticUrl = process.env.VUE_APP_STATIC_URL;
    let cleaned = _clean_uri(asset);
    if(absolute && !isValidUrl(staticUrl)){
        return resolve_domain_url(`${staticUrl}${cleaned}`);
    }
    return `${staticUrl}${cleaned}`;
}
export function resolve_domain_url(uri) {
    const http_protocol = process.env.VUE_APP_HTTP_PROTOCOL;
    const domain_name = process.env.VUE_APP_DOMAIN_NAME;
    let cleaned = _clean_uri(uri);
    return `${http_protocol}://${domain_name}/${cleaned}`;
}