import Vue from 'vue'
import Router from 'vue-router'
import Meta from "vue-meta";
// import VueDemo from '@/components/VueDemo'
// import Messages from '@/components/Messages

import HomeView from '@/components/HomeView.vue'
import PostDetailView from "@/components/PostDetailView.vue";
import AboutView from "@/components/AboutView.vue";
import ContactView from "@/components/ContactView.vue";


Vue.use(Router);
Vue.use(Meta);

// Routes;
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomeView,
      name: "home",
      pathToRegexpOptions: { strict: true }
    },
    {
      path: "/post/:interviewee/:post_id/",
      component: PostDetailView,
      name: "post",
      pathToRegexpOptions: { strict: true }
    },
    {
      path: "/about/",
      component: AboutView,
      name: "about",
      pathToRegexpOptions: { strict: true }
    },
    {
      path: "/contact/",
      component: ContactView,
      name: "contact",
      pathToRegexpOptions: { strict: true },
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// // This callback runs before every route change, including on page load.
// router.beforeEach((to, from, next) => {
//   // This goes through the matched routes from last to first, finding the closest route with a title.
//   // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  
//   const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

//   // Find the nearest route element with meta tags.
//   const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//   const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

//   // If a route with a title was found, set the document (page) title to that value.
//   if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

//   // Remove any stale meta tags from the document using the key attribute we set below.
//   Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

//   // Skip rendering meta tags if there are none.
//   if(!nearestWithMeta) return next();

//   // Turn the meta tag definitions into actual elements in the head.
//   nearestWithMeta.meta.metaTags.map(tagDef => {
//     const tag = document.createElement('meta');

//     Object.keys(tagDef).forEach(key => {
//       tag.setAttribute(key, tagDef[key]);
//     });

//     // We use this to track which meta tags we create, so we don't interfere with other ones.
//     tag.setAttribute('data-vue-router-controlled', '');

//     return tag;
//   })
//   // Add the meta tags to the document head.
//   .forEach(tag => document.head.appendChild(tag));

//   next();
// });

export default router;