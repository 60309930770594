import Vue from "vue";
import Vuex from "vuex";
import VueWaypoint from "vue-waypoint";

import posts from "./modules/posts";

import '@/utils/logger.js';

// global css
import '@/assets/bootstrap/bootstrap.css';
import "@/assets/bootstrap/bootstrap-overrides.css";

Vue.use(Vuex);

// Waypoint plugin
Vue.use(VueWaypoint);

export default new Vuex.Store({
  modules: {
    posts
  }
});
