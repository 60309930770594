<template>
        <div>
            <div class="row justify-content-center">
                <div class="col-12 col-sm-6">
                    <div class="postview-text">
                        <h2>CONTACT</h2>
                        <p>
                            Send us a body.
                        </p>                        
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="name">Your Name</label>
                            <input v-model="name" type="email" class="form-control" id="name" aria-describedby="nameHelp" placeholder="Enter your name.">
                            <small id="nameHelp" class="form-text text-muted"></small>
                        </div>
                        <div class="form-group">
                            <label for="email">Email address</label>
                            <input v-model="email" type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email">
                            <small id="emailHelp" class="form-text text-muted">We'll only use your email to respond to you.</small>
                        </div>
                        <div class="form-group">
                            <label for="body">Message</label>
                            <textarea v-model="body" class="form-control" id="body" placeholder=""></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary" v-on:click="submitContact">Send</button>
                </div>
            </div>
        </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import axios from 'axios';

import { getCookie} from "@/utils/logger";
import store from '@/store';
import {resolve_static_asset} from '@/utils/url_helpers';


export default {
    name: 'contact-view',
    // template: "#home-view",
    metaInfo() {        
      return {
            title: 'Contact',
        }
    },
    data() {
       return{
            email: 'jnable@serexpo.com',
            name: 'Jonathan Nable',
            body: 'Hello World',
            is_submitting: false
        }
    },
    computed: {},
    methods:{
        getStaticAsset(path){
            return resolve_static_asset(path)
        }, 
        submitContact(event){
            const data = {
               ...this.$data
            }
            // store.dispatch('posts/submitContact', data)
            this.$data.is_submitting = true
            // await this.submitContact(data)

            return false;
        },
        async submitContact({ state, commit }, data) {
            var cookie = getCookie("csrftoken");   
            try {
            let response = await axios(
                resolve_domain_url("/api/v1.0/posts/contact/"),
                {
                method: "POST",
                mode: "cors",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    // "Content-Type": "application/x-www-form-urlencoded",
                    "X-CSRFToken": cookie,
                },          
                }
            );
            // let json = await response.json();
            let data = await response.data;
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },        
    },
    mounted: function(){
        store.dispatch('posts/toggleBottomMenu', true);
    }
}
</script>
