import axios from 'axios';
import {
  resolve_static_asset,
  resolve_domain_url
} from "@/utils/url_helpers";
import { getCookie} from "@/utils/logger";

const state = {
  posts: [],
  limit: 10,
  next: 0,
  fetching: false,
  showBottomMenu: false,
};
const mutations = {
    setPosts(state, posts) {
        state.posts = posts;        
    },
    paginatePosts(state, next) {
        state.next = next;
    },
    setBottomMenu(state, show_hide){
        state.showBottomMenu = show_hide
    }
};
const actions = {
  async fetchPosts({ commit }) {
    try {
      let response = await axios(
        resolve_domain_url("/api/v1.0/posts/?limit=25&next=50"),
        //   "/static/_forall/posts.json",
        {
          method: "GET",
          mode: "cors"
        }
      );
      // let json = await response.json();
      let data = await response.data;
      commit("setPosts", data);
      commit("paginatePosts", 0);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }, 
  postsNext({ commit, state, dispatch }, prefetchNext = false) {
    const next = state.next + state.limit;
    // if there are 5 posts and next is greater return remaining
    if (state.posts.length <= next) {
      commit("paginatePosts", state.posts.length);
    } else {
      commit("paginatePosts", next);
    }
    if (prefetchNext) {
      dispatch("prefetchImgs");
    }
  },
  postsPrevious({ commit, state }) {
    if (state.next >= 0) {
      commit("paginatePosts", state.next - state.limit);
    } else {
      commit("paginatePosts", 0);
    }
  },
  prefetchImgs({ state }) {
    let start = state.next + state.limit;
    let next = 0;
    if (start === state.limit) {
      next = start * 2;
    } else {
      next = start + state.limit;
    }
    const posts = state.posts.slice(start, next);
    for (let index = 0; index < posts.length; index++) {
      let post = posts[index];
      document
        .createElement("img")
        .setAttribute(
          "src",
          resolve_static_asset(`_forall/${post.image_name}`)
        );
    }
  },
  toggleBottomMenu({commit}, show_hide){
      commit("setBottomMenu", show_hide);
  },
  async submitContact({ state, commit }, data) {
    var cookie = getCookie("csrftoken");   
    try {
      let response = await axios(
        resolve_domain_url("/api/v1.0/posts/contact/"),
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            // "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRFToken": cookie,
          },          
        }
      );
      // let json = await response.json();
      let data = await response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
  },   
};
const getters = {
    activePosts: state => {
        return state.posts.slice(0, state.next + state.limit);
    }
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
