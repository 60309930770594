<template>
     <div v-show="fetching" class="loading">
        <div class="loader"></div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    name:"load-spinner",
    computed: {
        ...mapState({
            fetching: state => state.posts.fetching,
        }),
    },    
}
</script>
<style>
.loading {
    position: fixed;
    top: 0; right: 0;
    bottom: 0; left: 0;
    background: rgba(255,255,255,.5)
}
.loader {
    left: 50%;
    margin-left: -4em;
    font-size: 10px;
    border: .5em solid rgba(218, 219, 223, 1);
    border-left: .5em solid rgba(60, 60, 60, 1);
    animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
